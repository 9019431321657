<template>
  <!-- eslint-disable -->
  <div class="container cont-terms cont-ambassadors">
    <div class="columns">
      <div class="column">
        <!-- HOJA 1 -->
        <div class="title-setup"><u>T&Eacute;RMINOS DE USO</u></div>
        <!-- PUNTO 1 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div>1</div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">
              SU ACEPTACI&Oacute;N
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          Al aceptar estos Términos de Uso, está declarando, bajo protesta de decir verdad, que es mayor de edad.
          <br />
          <br />
          El Usuario manifiesta que ha leído, entiende, está de acuerdo en cumplir y acepta como están, estos Términos
          de Uso del GIM, así como la información indicada en el Aviso de Privacidad, la Política de Privacidad, la
          Política de Cookies y los Términos de Servicio de GolStats y también que está de acuerdo en que GolStats
          recolecte la información personal que se precisa en los mismos, como la que se colecta a través de cookies, en
          la forma y términos que en ellos se especifica
        </p>
        <!-- PUNTO 2 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">2</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">¿Qué es el GIM?</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          GolStats Information Manager (en adelante, el “GIM”), es una aplicación de entretenimiento desarrollada por
          GolStats, S.A.P.I. de C.V. (en adelante, “GolStats”), que permite el acceso y/o manejo de ciertos datos e
          información usualmente referente a partidos de fútbol (de ahora en adelante, “Datos”, que incluye además lo
          que se ha establecido más adelante en el apartado de Definiciones), y que va dirigida únicamente a Usuarios
          que quieran utilizar la aplicación específicamente en concepto de diversión y entretenimiento. Ambos modelos,
          tanto su uso gratuito, así como por pago de suscripción, son válidos únicamente para uso personal y sólo para
          su entretenimiento y diversión; y, sus términos de uso están diseñados partiendo de las Definiciones, alcances
          y límites aquí establecidos.
        </p>
        <!-- PUNTO 3 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">3</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">Definiciones</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          Cuando las palabras siguientes se utilicen en estos Términos de Uso, con la primera letra en mayúscula (en
          singular o plural), salvo que el contexto requiera otra cosa o emplee una definición particular, tendrán los
          significados que se señalan a continuación:
          <br /><br />
          <strong>Apuesta:</strong> Monto susceptible de apreciarse en moneda de curso legal que se arriesga en un Juego
          de Azar, legal, con la posibilidad de obtener o ganar un premio, cuyo monto, en caso de ganar, sumado a la
          cantidad arriesgada deberá de ser superior a ésta. Una apuesta siempre conlleva el riesgo de perder el 100% de
          lo apostado por el apostador. <br /><br />
          <strong>Azar:</strong> Es una casualidad presente, teóricamente, en diversos fenómenos que se caracterizan por
          causas complejas, no lineales y sobre todo que no parecen ser predictibles en todos sus detalles y que es
          completamente ajeno a la voluntad del jugador. <br /><br />
          <strong>Casa de Apuesta:</strong> Empresa legal, que opera de forma independiente y separada de GolStats, cuyo
          objetivo comercial es ofrecer juegos de azar, usualmente involucrando dinero. <br /><br />
          <strong>Datos:</strong> Probabilidades, estadísticas, Momios (incluyendo la comparación entre diversos
          Momios), nombres de equipos o jugadores, información histórica, información pública referente a partidos de
          fútbol y los torneos en general, que se relacionan con el fútbol. <br /><br />
          <strong>Ganancia:</strong> Premio definido y ofrecido por la Casa de Apuesta que usted elija, en caso de que
          acierte en la Apuesta que realice. <br /><br />
          <strong>Ganas/Pierdes:</strong> Simulador que permite calcular cuál sería la Ganancia en Probabilidad si
          perdiera una o varias de las apuestas. <br /><br />
          <strong>Juegos de Azar:</strong> Son juegos en los cuales las posibilidades de ganar o perder no dependen
          exclusivamente de la habilidad del jugador, sino que interviene también el Azar. <br /><br />
          <strong>Momio:</strong> Es la relación que existe entre la posibilidad que se presente un resultado, o no. Es
          decir, es la Probabilidad de que un equipo gane, pierda o empate, dependiendo del deporte. El momio representa
          la cantidad que, en caso de acertar, percibiría como premio por parte de la Casa de Apuesta, cuya cantidad
          final depende del Monto de su apuesta. <br /><br />
          <strong>Monto:</strong> Cantidad de dinero, supuesta o real, considerada por el Usuario para jugar.
          <br /><br />
          <strong>Monto + Ganancia:</strong> Estimación de la cantidad de dinero que incluye la ganancia potencial,
          sumada al Monto. <br /><br />
          <strong>Opinión:</strong> Idea, juicio o concepto subjetivo que una persona física o moral tienen acerca de algo o alguien.
          <br /><br />
          <strong>Probabilidad:</strong> Resulta de un modelo matemático de interpretación que intenta medir la certeza
          de que un evento suceda. Existen diferentes formas de obtener esta medición, y usualmente se basa en el
          análisis de datos históricos. La certeza en el fútbol puede ser variable, impredecible y poco certera, dado
          que las condiciones son constantemente cambiantes y no son condiciones estables. <br /><br />
          <strong>Pronóstico Optimista:</strong> Que propende a ver y juzgar una probabilidad en su aspecto más
          favorable de que suceda un evento. <br /><br />
          <strong>Riesgo:</strong> La Probabilidad de perder total o parcialmente el dinero apostado. El riesgo puede
          variar según la Probabilidad de que suceda un evento en específico. <br /><br />
          <strong>Torneo:</strong> Competencia organizada de partidos en donde participan dos o más equipos de fútbol,
          de cualquier división, país, categoría o sexo. <br /><br />
          <strong>Usuario:</strong> Comprende al Usuario Free y Usuario Premium. <br /><br />
          <strong>Usuario Free:</strong> Es una persona que, sin costo alguno, puede hacer uso de ciertas
          funcionalidades del GIM. <br /><br />
          <strong>Usuario Premium:</strong> Es una persona que tiene acceso a funcionalidades adicionales a las que se
          ofrecen al Usuario Free del GIM, las que se indican en el paquete, mediante el pago de una cuota, durante el
          periodo contratado. <br /><br />
          <strong>Variable:</strong> Que varía o puede variar. Inestable, inconstante y mudable.
        </p>
        <!-- PUNTO 4 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">4</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">Condiciones de uso</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          Al contratar este servicio, el Usuario declara ser mayor de edad. Bajo ninguna circunstancia se acepta el uso
          del GIM por menores de edad.
          <br /><br />
          Para Usuarios Premium, es indispensable estar al corriente en el pago de las cuotas aplicables para hacer uso
          de todas las funcionalidades.
          <br /><br />
          Las funcionalidades disponibles para los Usuarios Free y Premium varían constantemente, lo que implica que,
          sin previo aviso a los Usuarios y a la sola discreción de GolStats, se puedan agregar, modificar o eliminar
          funcionalidades.
          <br /><br />
          Es indispensable cumplir todo el tiempo con las políticas de uso vigentes y que en su caso emita o actualice
          de tiempo en tiempo GolStats.
        </p>
        <!-- PUNTO 5 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">5</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">Limitación/exención de responsabilidad</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          Los Datos pueden ser recolectados, generados o calculados por GolStats o por terceros. GolStats muestra los
          Datos a través de esta plataforma GIM, que fue creada únicamente para el entretenimiento y diversión, y es
          posible que haya errores en los Datos o en la presentación de los mismos, por lo que GolStats no se hace
          responsable de cualquier consecuencia que pueda generarle al Usuario el uso o aplicación de los Datos,
          incluyendo, pero no limitándose a la pérdida de dinero en Juegos de Azar y Apuestas.
          <br /><br />
          No reconocemos a las Apuestas como una fuente de ingreso económico sustentable; y, por ello, los Datos son
          proporcionados para que el Usuario encuentre un soporte organizado y estructurado, adicional a su buen saber,
          para una diversión y entretenimiento sano.
          <br /><br />
          Realizar una Apuesta conlleva el Riesgo de perder total o parcialmente el dinero apostado. Las personas pueden
          desarrollar una enfermedad de adicción a la apuesta, llamada ludopatía. Previo a apostar o incluso de hacer
          uso de la plataforma GIM, se recomienda consultar a las instituciones, médicos y/u otros profesionales
          adecuados, informarse y tomar oportunamente y con la diligencia debida, las medidas pertinentes.
          <br /><br />
          Los Datos pudieran contener errores involuntarios; por ejemplo, es posible que haya una diferencia entre el
          valor medido o calculado y el real. Asimismo, la actualización de la información de los Datos está sujeta a
          errores, por lo que GolStats no se hace responsable de ello.
          <br /><br />
          Dado que la Probabilidad se alimenta de los Datos, de contener errores, pudieran arrojar resultados sesgados.
          <br /><br />
          El fútbol es un deporte cuyo resultado es esencialmente inestable, inconstante y mudable, sujeto al Azar.
          <br /><br />
          El momento, el Monto y la forma de uso, destino, riesgos y consecuencias que pudieran resultar de la
          aplicación de los Datos que arrojen los cálculos de probabilidad del GIM queda bajo responsabilidad única y
          exclusiva del Usuario.
          <br /><br />
          GolStats puede obtener, directamente o a través de terceros, manualmente o automáticamente, los Momios
          ofrecidos por las Casas de Apuesta. Los Momios que se muestren en esta plataforma GIM, o cualquier otra
          plataforma de GolStats o de terceros, pueden contener errores, estar desfasados o desactualizados. GolStats
          puede hacer referencia a que los Momios pertenecen a cierta Casa de Apuesta y pueden ser mostrados, comparados
          o contextualizados en la plataforma GIM o cualquier otra plataforma de GolStats o de terceros, aún y cuando
          sean erróneos, por lo que GolStats no garantiza que los Momios estén libres de defectos o errores. En razón de
          lo antes establecido, es obligación exclusiva del Usuario, verificar y validar los Momios directamente en las
          Casas de Apuesta correspondientes, para asegurarse de su exactitud, permanencia y pertinencia.
          <br /><br />
          El Usuario, previo a realizar cualquier apuesta, deberá asegurarse y cotejar que la información del Momio esté
          actualizada y coincida con la que le muestra el GIM y estar cierto de que es su voluntad llevarla a cabo,
          asumiendo los riesgos implícitos en las Apuestas, los Juegos de Azar, y las inconsistencias que pudieran
          existir en los Datos y/o Probabilidades.
          <br /><br />
          El Usuario, por el sólo hecho de hacer uso de la aplicación GIM, declara haber leído, entendido, y en su caso,
          aclarado, y estar consciente de los riesgos implícitos o potenciales en el uso de los Datos, así como de esta
          aplicación. Asimismo, declara que conoce y comprende el significado de: Apuesta, Juego de Azar, así como de
          Probabilidad, Momios y Datos sujetos a errores.
          <br /><br />
          El Usuario reconoce que el GIM no es una Casa de Apuesta, no cuenta con funcionalidades para organizar juegos,
          administrar, gestionar, manejar o recibir dinero para destinarlo a apuestas deportivas o juegos de azar ni
          para entregar premios; tampoco opera establecimientos o sitios que mediante instrumentos tecnológicos se
          apueste; no obtiene comisiones sobre apuestas realizadas o por premios que el Usuario reciba de Casas de
          Apuesta y no avala o tiene incidencia alguna en la operación de las Casas de Apuestas o sitios dedicados a las
          Apuestas, pues es una plataforma independiente de cualquier sistema de Apuestas, de sus requisitos, términos
          de uso, cumplimiento normativo, creación de cuentas, montos, ofertas, manejo de depósitos, pagos, ganancias,
          premios, devoluciones, reclamos, etcétera. Derivado de lo anterior, el Usuario que elige Apostar en cualquier
          Casa de Apuestas, está obligado a conocer sus derechos de apostador, directamente en el lugar de apuestas que
          elija, tales como: cobrar y recibir sus ganancias, conocer las reglas de los juegos con apuestas, se respeten
          las promociones ofrecidas, obtener ganancias justas, protección de sus datos personales, o interposición de
          quejas, entre otros.
          <br /><br />
          Cualquier funcionalidad en la que el Usuario Free o Premium al hacerle clic a un ícono lo redireccione al
          sitio web o aplicación de una o más Casas de Apuesta, queda claro que esa funcionalidad es meramente en
          concepto de facilitar el acceso al Usuario para cuando el Usuario le haga clic, y que en ese momento estaría
          abandonando el sitio GIM y direccionándolo a la plataforma del tercero elegido por el Usuario, por lo que
          GolStats no avala ni es responsable sobre lo que suceda en la plataforma de dicho tercero. Queda claro que las
          funcionalidades y herramientas del GIM deben emplearse únicamente como entretenimiento y diversión en
          cualquiera de las Casas de Apuestas sobre fútbol con equipos o ligas que el GIM presenta, por lo que es
          responsabilidad exclusiva del Usuario si decide ingresar o no a sitios de Casas de Apuesta, a cuál de todas
          ellas elige, si participa o no con Apuestas, y en tal caso, determinando el mismo Usuario la forma, el momento
          y el monto.
          <br /><br />
          Es posible que el GIM esté presente en diferentes sitios web o aplicaciones, incluyendo aquellos de terceros,
          incluso en sitios o aplicaciones que sean Casas de Apuesta. Esto no significa que el GIM o GolStats, respecto
          de dichos terceros, avalen, respalden, fomenten, sugieran, promuevan o de cualquier forma
          recomienden lo que ofrezcan o suceda en dichas plataformas o los negocios a ellas asociados. Por ello, se
          aclara que, el hecho de estar presente en diferentes sitios web, es con el único objetivo de facilitarle el
          acceso al Usuario Free y Premium.
          <br /><br />
          No se garantiza que el GIM esté disponible o visible en otras plataformas, ni tampoco, cuando aparezca en
          ellas, garantiza su permanencia en las mismas.
          <br /><br />
          No nos hacemos responsables de retrasos en la actualización de la información, ni nos obligamos a tener
          información específica por partido, ni tampoco nos comprometemos a tener Datos de ciertos partidos, ni a
          completar la información de un partido en específico, ni a cierto alcance o profundidad de la información (por
          ejemplo, la categorización del tipo de Datos), ni al partido completo (por ejemplo, tener los datos únicamente
          del primer tiempo pero no del segundo tiempo, por lo que no se tendría la información del partido completo),
          ni a incluir Datos de ciertos Torneos, pudiendo tener información incompleta de Torneos. En razón de lo
          anterior, GolStats puede decidir en cualquier tiempo, de manera unilateral y sin la necesidad de consultar o
          necesitar la autorización de sus Usuarios, dejar de actualizar parcial o totalmente cualquier Torneo o
          partido. El Usuario al usar el GIM, acepta que los Datos a los que tendrá acceso son “como están”, y si en su
          momento considera que ya no tiene acceso a la información de su interés, puede eliminar su suscripción y dejar
          de utilizar la plataforma, pero NO tiene derecho de exigirle a GolStats la entrega o actualización de cierta
          información.
          <br /><br />
          Si bien el GIM está diseñado para un funcionamiento permanente, GolStats no se compromete a mantener la
          aplicación en constante operación y libre de errores, pues puede haber intermitencias o incidencias propias o
          de terceros que no lo hagan posible. Al aceptar los Términos de Servicio, el Usuario está de acuerdo en que
          pudiera haber errores e intermitencias y que no darán lugar a reclamos o reembolsos.
          <br /><br />
          GolStats tiene el derecho de modificar, agregar o eliminar funcionalidades, visualizaciones, secciones y demás
          aspectos o contenidos dentro de su plataforma, por decisión unilateral, sin la necesidad de consultar o
          necesitar la autorización de sus Usuarios Free y/o Premium.
          <br /><br />
          GolStats se reserva el derecho de cancelar sin previo aviso y en cualquier tiempo el GIM.
          <br /><br />
          GolStats se reserva el derecho a emitir Opiniones. Estas Opiniones son subjetivas y pueden ser erróneas, por
          lo que se deben considerar como tales y de ser recibidas, tomarlas con la subjetividad que se involucra, “como
          están”, por lo que los Usuarios son los únicos responsables del uso posible que podrían hacer de las mismas.
        </p>
        <!-- PUNTO 6 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">6</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">Uso responsable del GIM</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          El Usuario podrá autoexcluirse en cualquier momento del uso del GIM por vía de la inhabilitación (temporal o
          permanente) de su cuenta. La exclusión no conlleva devolución alguna o reembolso de pagos ya efectuados.
          <br /><br />
          Por otro lado, GolStats se reserva el derecho de inhabilitar el uso a los Usuarios que considere conveniente,
          de manera permanente o temporal. Esta decisión se puede tomar por GolStats de manera unilateral.
        </p>
        <!-- PUNTO 7 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">7</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">Membresía Premium</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          El Usuario Premium elegirá de entre las opciones disponibles, el periodo inicial de suscripción de su
          conveniencia, con renovaciones automáticas por el mismo periodo. El periodo inicial y, en su caso, las
          renovaciones se mantendrán vigentes durante la totalidad del periodo en curso, sin posibilidad de cancelación,
          una vez contratado o renovado. Mientras no ocurra su renovación, la membresía es cancelable en cualquier
          tiempo por el Usuario. Al cancelar, seguirá disponiendo del GIM hasta que termine el periodo en curso, y ya no
          tendrá cargo alguno para el siguiente. Se aclara que, una vez hecho el cargo por renovación no existe la
          opción de devolución del dinero, pues sólo aplica la cancelación de cargos por periodos futuros.
          <br /><br />
          El periodo de contratación inicia al momento de que se recibe el pago el Usuario Premium y termina a las 11:59
          p.m. del último día del periodo contratado.
          <br /><br />
          GolStats se reserva el derecho de cancelar sin previo aviso y en cualquier tiempo el GIM o cualquier
          suscripción de Usuario Free (sin responsabilidad alguna) o de Usuario Premium. En tal caso, a los Usuarios
          Premium les rembolsará la cantidad que corresponda al periodo que reste, contado desde el día en que ocurra la
          cancelación y hasta el día en que concluya el periodo en curso. A manera de ejemplo: Si GolStats cancela una
          suscripción Premium el día 1° de agosto y el Usuario había pagado un año desde el 1° de enero al 31 de
          diciembre, entonces, la cantidad a rembolsar sería la parte proporcional del pago que corresponda a los días
          sin posibilidad de uso, que serían del 1° de agosto al 31 de diciembre. El rembolso sucedería únicamente
          cuando fuera GolStats quien decidiera inhabilitar a un Usuario Premium. Si fuera el Usuario Premium quien
          cancelara, como se mencionó anteriormente, no aplica la devolución del dinero ya pagado, por lo que, en el
          ejemplo anterior, si el Usuario Premium pagara por una suscripción anual iniciando del 1 de enero al 31 de
          diciembre, y decidiera cancelar el 1 de agosto, NO se le reembolsaría ninguna cantidad, y el Usuario Premium
          podría seguir disfrutando de tener acceso a la plataforma hasta el 31 de diciembre, y ya no se renovaría
          automáticamente el siguiente año.
          <br /><br />
          Por la naturaleza del fútbol, existen semanas, meses o temporadas en las que no hay partidos de fútbol, como
          es usualmente en verano, invierno, fechas FIFA, partidos cancelados o pospuestos, incluyéndose casos fortuitos
          o de fuerza mayor, entre otros. El pago por parte del Usuario Premium no tiene ninguna variación o descuento
          para estos periodos o circunstancias. Es decir, el Usuario Premium no tiene derecho a reclamar la devolución
          de su dinero o a exigir que su pago sea por un monto menor para temporadas o periodos o por circunstancias
          bajo las cuales no haya fútbol.
        </p>
        <!-- PUNTO 8 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">8</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">Funcionalidades adicionales</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          GolStats podrá hacer disponible por tiempo limitado, con o sin cargo, funcionalidades adicionales, en cuyo
          caso el Usuario podrá hacer uso de las mismas, bajo las condiciones que se ofrezcan.
        </p>
        <!-- PUNTO 9 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">9</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">Ofertas limitadas</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          GolStats podrá ofrecer paquetes con descuento por tiempo limitado. En las renovaciones al término del periodo
          elegido de dicho paquete, si no hay cancelación previa, el cargo se hará por el precio vigente en el momento
          de la renovación automática.
          <br /><br />
          El Usuario Premium es quien elige el periodo contratado al momento de suscribirse, de las opciones ofrecidas
          por el GIM. Por lo tanto, es responsabilidad del Usuario Premium revisar la actualización de precios antes de
          que termine la vigencia de su periodo contratado, para que él mismo, de manera informada decida si desea o no
          seguir con el Servicio para próximos periodos. En el caso de que el Usuario Premium no cancele su suscripción
          antes de concluir el periodo (inicial o de renovación automática), se da por entendido que el Usuario Premium
          está enterado de que su periodo está por terminar, y está de acuerdo en que su suscripción se renovará
          automáticamente por otro periodo igual al que contrató de acuerdo con el precio vigente al momento de la
          renovación.
          <br /><br />
          En caso de que no exista idéntico plan en la periodicidad y con el fin de que el Usuario Premium mantenga la
          continuidad del Servicio, el Usuario Premium está de acuerdo en que el GIM le asigne el más parecido con el
          cargo correspondiente. En este caso, si no hubo uso del plan asignado, el Usuario podrá cancelarlo y solicitar
          el reembolso dentro de los siguientes 30 (treinta) días naturales. Transcurrido dicho plazo, con o sin uso, se
          entenderá aceptado por el Usuario Premium y no admitirá reembolso alguno.
        </p>
        <!-- PUNTO 10 -->
        <div class="cont-terms__section">
          <div class="option-terms">
            <div class="num-option-terms">
              <div><span class="num-option-num-terms">10</span></div>
            </div>
            <div class="cont-name-option-terms-uno is-uppercase">Resolución de conflictos</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <p>
          Cualquier controversia o reclamación que surja del uso del GIM o se relacione con el mismo, así como el
          incumplimiento de cualquiera de las partes, deberá solucionarse de buena fe por vía de negociación amistosa.
          En caso de no llegarse a un acuerdo mutuo en un término de 30 (treinta) días naturales desde la fecha en que
          se notificó por escrito la inconformidad, reclamación o incumplimiento, las partes someterán el conflicto a
          mediación ante el Instituto de Justicia Alternativa del Estado de Jalisco, México.
          <br /><br />
          GolStats y el Usuario renuncian expresamente a acudir a cualquier fuero judicial, pues se obligan
          recíprocamente a agotar el procedimiento alternativo de mediación ante el Instituto de Justicia Alternativa
          del Estado de Jalisco respecto de las controversias o reclamaciones entre las partes en relación con los
          términos y condiciones para el uso de esta la herramienta GIM, quien tendrá el propósito de facilitar los
          acuerdos respecto de supuestos incumplimientos totales o parciales a los mismos. Dicho procedimiento
          alternativo se llevará a cabo en la ciudad de Guadalajara, Jalisco, México, y el acuerdo a que se llegue será
          definitivo, pudiéndose ejecutar ante cualquier tribunal que tenga jurisdicción para tales efectos.
          <br /><br />
          De no llegar a un acuerdo, las partes se someten a las leyes y jurisdicción de los tribunales competentes de
          la ciudad de Guadalajara, Jalisco, México, renunciando expresamente a cualquier otro fuero o legislación
          aplicable que por razón del domicilio u otra causa pudiera corresponderles.
          <br /><br />
          Puede dirigir cualquier inquietud, queja o conflicto al correo electrónico:
          <a href="mailto:contacto@golstats.com">contacto@golstats.com</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms-V1.0.0',
};
</script>

<style scoped lang="scss">
$gray-42: #424242;
.cont-ambassadors {
  margin-top: 50px;
  padding-top: 100px;
  position: relative;
}
.title-setup {
  font-size: 92px;
  line-height: 99px;
  color: #2d2d2d;
  font-family: 'Avenir-Black';
  @media (max-width: 1440px) {
    font-size: 68px;
    line-height: 95px;
  }
  @media screen and (max-width: 1230px) {
    font-size: 74px;
    line-height: 80px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 47px;
  }
  @media (max-width: 480px) {
    font-size: 40px;
    line-height: 45px;
  }
}
.name-option-terms {
  border: none;
  font-size: 24px;
  text-align: left;
  padding-left: 17px;
}
.cont-name-option-terms.active {
  width: auto;
}
.cont-terms {
  text-align: left;
  & p {
    font-family: 'Avenir-Roman', sans-serif;
    font-size: 20px;
    margin: 10px 0 10px 12px;
    color: $gray-42;
  }
  & ul {
    padding-left: 35px;
    text-align: center;
    list-style-position: outside;
    font-family: 'Avenir-Roman', sans-serif;
    color: $gray-42;
    &.bullets li {
      list-style: initial;
      margin: 10px 0;
    }
  }
  & li ul {
    padding-left: 22px;
  }
  li {
    list-style: upper-latin;
    font-size: 20px;
    text-align: left;
    padding-left: 10px;
    margin: 10px 0;
  }
  & a {
    color: #021149;
    text-decoration: underline;
    &:hover {
      color: #0086cf;
    }
  }
  &__section {
    margin-left: -12px !important;
    margin-top: 72px;
  }
}
.option-terms {
  display: flex;
  align-items: flex-start;
}
.num-option-terms {
  padding-left: 20px;
  margin-right: 26px;
  font-size: 41px;
  color: $gray-42;
  text-align: center;
  font-family: 'Avenir-Black';
}
.cont-name-option-terms-uno {
  font-family: 'Avenir-Heavy';
  width: auto;
  font-size: 26px;
  font-weight: bold;
  line-height: 54px;
  color: $gray-42;
  padding-top: 7px;
}
@media (min-width: 1441px) {
  .container {
    width: 1410px;
  }
  .container.cont-terms {
    width: 1140px;
  }
}
</style>
